import React from 'react'

const Section1 = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''
  return (
    <section class={`feature-themes ${alternateBG}`}>
      <div class="wrapper">
        <div class="feature-theme-text ">
          <img class="feature-theme-image" src="/logo_xd.png" srcset="/logo_xd.png 2x" alt="Airmail Logo " width="250px" />

          <h2 class="section-header">RSS Feeds Your Way</h2>
          <p>
            Veen is designed from the ground up to be a powerful RSS client which allows you to customize it to meet your needs. Integrate your favorite apps, and create custom actions to make your
            life easier. Whether you're using your Android, Web Browser, or Windows machine, Veen can be wherever you need.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Section1
