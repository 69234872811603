import React from 'react'

const Feature = ({ title, body }) => {
  return (
    <ul class="features-glance-list">
      <li>
        <b>{title} </b>
        <br /> {body}
      </li>
    </ul>
  )
}

const Features = [
  {
    title: 'Multiple Accounts ',
    body: 'Add multiple accounts to enjoy reading in a different context or as a different user.',
  },
  {
    title: 'Switch Account ',
    body: 'Smoothly switch between multiple accounts.',
  },
  {
    title: 'Beautiful dashboard ',
    body: 'View your stories in a three-pane distraction free  dashboard.',
  },
  {
    title: 'Subscribe to any website ',
    body: 'Enter website url, topic or hashtag and subscribe to it.',
  },
  {
    title: 'Manage subscriptions',
    body: 'Use Veen to manage your subscriptions and underlying services like Feedly, Foundry, etc...',
  },
  {
    title: 'Favourite ',
    body: 'Add any story to your list  of favorite ones ',
  },
  {
    title: 'Tags',
    body: 'Create as many as tags you want, and then add tags to stories to group them ',
  },
  {
    title: 'Web View ',
    body: "Quickly view any story's website  in Veen",
  },
  {
    title: 'Article View ',
    body: 'Just click on the bulb icon to read any article in clear text in Veen',
  },
  {
    title: 'Dark Mode',
    body: 'Set Veen to use light or dark mode',
  },
  {
    title: 'Sync',
    body: 'Any activity done on any devices is sycned accoss all devices',
  },
  {
    title: 'Multiple Language',
    body: 'Choose any language of your choice',
  },
]

const Component = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''
  return (
    <section class={`feature-themes ${alternateBG}`}>
      <div class="wrapper">
        <a name="features" id="android" name="android"></a>
        <h2 class="section-header">Veen for Android</h2>
        <div class="central-image">
          <img class="feature-theme-image multiply" src="/android.jpg" srcset="/android.jpg 2x" alt="Veen for Android" />
        </div>
        <br /> <br />
        {Features.map((f) => (
          <Feature {...f} />
        ))}
      </div>
    </section>
  )
}

export default Component
