import React from 'react'

const Component = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''
  return (
    <section class={`feature-themes ${alternateBG}`}>
      <div class="wrapper">
        <div class="feature-theme-text">
          <h2 class="section-header">Localization</h2>
          <p>Veen supports the following languages: English and Russian. Many more to be added.</p>
        </div>
        <img class="feature-theme-image" src="./countrys-flags_1.png" srcset="./countrys-flags_1.png 2x" alt="Veen" />
      </div>
    </section>
  )
}

export default Component
