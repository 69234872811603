const config = {
  CONTACT_EMAIl: 'veenreader@gmail.com',
  VEENREADER_WEB_APP_URL: 'https://app.veenreader.com',
  VEENREADER_WEBSITE_URL: 'https://veenreader.com',
  VEENREADER_ANDROID_APP_URL: 'https://play.google.com/store/apps/details?id=com.veen',
  // VEENREADER_IOS_APP_URL: 'veenreader@gmail.com',
  VEENREADER_WINDOWS_APP_URL: '//www.microsoft.com/store/apps/9wzdncrdc28r?cid=storebadge&ocid=badge',
  VEENLABS_WEBSITE_URL: 'https://veenlabs.com',
}

export default config
