import React from 'react'
import config from '../config'

const Component = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''
  return (
    <div class={`feature-lock ${alternateBG}`}>
      <footer class="footer wrapper">
        <ul class="footer-nav">
          <li>
            <a href="https://twitter.com/veenlabs" name="Veen's on Twitter">
              Twitter
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/veenlabs" name="Veen's on Facebook">
              Facebook
            </a>
          </li>
        </ul>
        <br />

        <ul class="footer-nav">
          {/**
                 * 
                 *   <li><a href="https://www.iubenda.com/privacy-policy/519354/legal">Privacy</a></li>
          <li><a href="https://airmailapp.com/privacymac.html">Privacy Windows</a></li>
          <li><a href="https://airmailapp.com/privacyios.html">Privacy Android</a></li>
                 */}
          <li>
            <a href="/beta">Beta Programs</a>
          </li>

          <li>
            <a href={`mailto:${config.CONTACT_EMAIl}`} name="Airmail's on Twitter">
              Contact
            </a>
          </li>
          <li>
            <a href={`${config.VEENLABS_WEBSITE_URL}`} name="Veenlabs.">
              Veenlabs.
            </a>
          </li>
        </ul>

        <br />

        <div class="trademarks">
          Windows, the Windows logo, Windows machine are trademarks of Microsoft Corporation,registered in the U.S. and other countries. <br /> Windows Store is a service mark of Microsoft
          Corporation.
        </div>
        <br />

        <div class="trademarks">Google Play and the Google Play logo are trademarks of Google LLC.</div>
      </footer>
    </div>
  )
}

export default Component
