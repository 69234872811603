import React, { Component } from 'react'

function Header() {
  return (
    <div className="header alternate-bg">
      <div className="wrapper">
        <nav className="navigation multiply">
          <a href="/" name="homepage">
            <img
              className="navigation-logo"
              src="/logo_xd.png"
              width="48"
              height="48"
              style={{
                marginTop: 0,
              }}
            />
          </a>

          <ul className="navigation-elements">
            <li className="navigation-item ">
              <a href="/" name="homepage">
                {' '}
                Veen{' '}
              </a>
            </li>
            <li className="navigation-item navigation-lang">
              <a href="#windows" name="Veen on Windows">
                Windows
              </a>
            </li>
            <li className="navigation-item navigation-lang">
              <a href="#android" name="Veen on Android">
                Android
              </a>
            </li>
            <li className="navigation-item navigation-lang">
              <a href="#browser" name="Veen on browser">
                Web Browser
              </a>
            </li>
          </ul>
        </nav>

        <h1 className="header-title">Lightning Fast RSS Reader </h1>
        <h2 className="header-tagline">for Windows, Android and Web</h2>

        <h2 className="header-tagline">
          <img className="feature-theme-image" src="/file8.jpg" />
          <br />
        </h2>

        <div className="home-app-store-buttons">
          <a id="button_download_windows" title="Download Veen for Windows" name="Download Veen for Windows" href="https://app.veenreader.com">
            <img
              src="/web_badge.png"
              alt="English badge"
              alt="Download Veen on the App Store"
              width="auto"
              height="48"
              style={{
                borderRadius: 10,
                marginLeft: 2,
              }}
            />
          </a>

          <a id="button_download_windows" title="Download Veen for Windows" name="Download Veen for Windows" href="//www.microsoft.com/store/apps/9wzdncrdc28r?cid=storebadge&ocid=badge">
            <img
              src="https://developer.microsoft.com/store/badges/images/English_get-it-from-MS.png"
              alt="English badge"
              alt="Download Veen on the App Store"
              width="auto"
              height="48"
              style={{
                borderRadius: 10,
                marginLeft: 2,
              }}
            />
          </a>

          <a id="button_download_android" title="Download Veen for Windows" name="Download Veen for Windows" href="https://play.google.com/store/apps/details?id=com.veen">
            <img
              src="/google-play-badge.png"
              alt="English badge"
              alt="Download Veen on the App Store"
              width="auto"
              height="48"
              style={{
                borderRadius: 10,
                marginLeft: 2,
              }}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
