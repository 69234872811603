import React, { Component } from 'react'
import PageWrapper from '../components/PageWrapper'

import Header from '../components/Header'
import App_Swag from '../components/App_Swag'
import Features from '../components/Features'
import Windows from '../components/Windows'
import Android from '../components/Android'
import Browser from '../components/Browser'
import Localisation from '../components/Localisation'
import Footer from '../components/Footer'
import Pricing from '../components/Pricing'

export default class index extends Component {
  render() {
    return (
      <PageWrapper>
        <Header />
        <App_Swag />
        <Features alternateBG />
        <Windows />
        <Android alternateBG />
        <Browser />
        <Localisation alternateBG />
        <Pricing />
        <Footer alternateBG />
      </PageWrapper>
    )
  }
}
