import React from 'react'

const Feature = ({ title, detail }) => {
  return (
    <div>
      <h2 class="section-header-detail">{title}</h2>
      <p class="section-sub-header-detail">{detail}</p>
    </div>
  )
}

const FEATURES_LIST = {
  MULTIPLE_ACCOUNT: {
    title: 'Multi Account',
    detail: 'With one Veen account, you can add as many RSS reading service accounts on any type your like to Veen (Feedly, Foundry)',
  },
  MULTIPLE_DEVICES: {
    title: 'Multiple devices',
    detail: 'Veen supports multiple devices, Windows,  Android, and Web-Browser. Very soon it will support Mac and iOS too',
  },
  BEATUTIFUL_DESIGN: {
    title: 'Beautiful design',
    detail: 'Veen has a beautiful design, that provides distraction free reading',
  },
  TAG_READ_LATER: {
    title: 'Tags, favorites and read later',
    detail: 'Use Veen to add any story to a list of "favorites", "Read Later" or to a custom Tag',
  },
  CLUTTER_FREE_READING: {
    title: 'Clutter free reading',
    detail: 'Convert any article to distraction-free reading mode by just clicking one button',
  },
  MULTIPLE_LANGUAGE: {
    title: 'Multiple Languages',
    detail: 'Veen supports many languages, many more to be added soon.',
  },
}

const Features = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''

  return (
    <section class={`feature-themes ${alternateBG}`}>
      <div class={`home-intro wrapper `}>
        <section class={`home-into-section`}>
          <Feature {...FEATURES_LIST.MULTIPLE_ACCOUNT} />
          <Feature {...FEATURES_LIST.MULTIPLE_DEVICES} />
          <Feature {...FEATURES_LIST.BEATUTIFUL_DESIGN} />
        </section>

        <section class="home-into-section">
          <Feature {...FEATURES_LIST.TAG_READ_LATER} />
          <Feature {...FEATURES_LIST.CLUTTER_FREE_READING} />
          <Feature {...FEATURES_LIST.MULTIPLE_LANGUAGE} />
        </section>
      </div>
    </section>
  )
}

export default Features
