import React from 'react'

const Component = ({ alternateBG }) => {
  alternateBG = alternateBG ? 'alternate-bg' : ''
  return (
    <section class={`feature-lock feature-themes ${alternateBG}`}>
      <div class="wrapper">
        <div class="pricing-intro">
          <h2 class="section-header">Pricing</h2>
          <p>Veen is a free to use sofware on Windows, Android and Web Browser</p>

          {/**
               * 
               *           <br />
          <p>
            <b>Airmail Pro</b> subscription has two options:
          </p>
          <br />
          <ul>
            <p>
              <b>$2.99 monthly</b> which offers a free trial.
            </p>
            <p>
              <b>$9.99 yearly</b> which renew every year.
            </p>

            <br />
            <p>Prices may vary based on your region or current promotions.</p>
            <br />
          </ul>

          <p>
            <b>Airmail for Business</b> is one single purchase <b>$49.99 </b>Business and Education discount follow Apple Volume Purchase guideline
          </p>
          <br />
          <ul>
            <p class="trademarks">
              {' '}
              Airmail Pro is free for all users that are subscribed to Airmail Pro for iOS or have purchased Airmail 3 since 1st January 2019. <br />
              Previous users can still use Airmail with all the old features under Preferences>General> Airmail Legacy. <br /> New Users can try Airmail without Multi Account, and limited
              capabilities.
            </p>
          </ul>
               */}
        </div>
      </div>
    </section>
  )
}

export default Component
